import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  ColumnLayout,
  Input,
} from '@amzn/awsui-components-react/polaris';

class EndpointIdForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      endpointId: null,
      enableNextButton: false,
      isValidEndpointId: true,
      redirectToEndpointPage: false,
    };
    this.onClick = this.onClick.bind(this);
    this.onInput = this.onInput.bind(this);
  }

  render() {
    if (this.state.redirectToEndpointPage === true) {
      return <Redirect to={`/endpoints/${this.state.endpointId}`} />;
    }

    return (
      <div className="awsui-util-container max-width-container">
        <ColumnLayout columns={3}>
          <div data-awsui-column-layout-root="true">
            <div>Client VPN endpoint ID</div>
            <div>
              <Input
                placeholder="e.g., cvpn-endpoint-xyz"
                onInput={this.onInput}
              />
            </div>
            <div>
              <Button
                disabled={!this.state.enableNextButton}
                onClick={this.onClick}
                variant="primary"
              >
                Next
              </Button>
            </div>
          </div>
        </ColumnLayout>
      </div>
    );
  }

  onInput(e) {
    const endpointId = e.detail.value;
    this.setState({
      endpointId: endpointId,
      enableNextButton: endpointId.match(/^cvpn-endpoint-[a-f0-9]+$/),
    });
  }

  onClick(e) {
    this.setState((state) => ({
      redirectToEndpointPage: true,
    }));
  }
}

export default EndpointIdForm;
