export const API_ENDPOINT = '/api/v1';
export const HAS_MUTUAL_AUTH_COOKIE = 'HasMutualAuth';
export const IS_SAML_AUTH_COOKIE = 'IsSamlAuth';
export const IS_SAML_LOGOUT_COOKIE = 'IsSamlLogout';
export const SESSION_TOKEN_COOKIE = 'SessionToken';

export const ERROR_MESSAGE_INVALID_CREDENTIALS =
  'Invalid user credentials, please try again.';
export const ERROR_MESSAGE_SERVICE_API_ERROR =
  'Something went wrong with your request, please try again.';
export const ERROR_MESSAGE_THROTTLED_REQUEST =
  'Looks like too many requests are being made from your IP. Please retry your request after some time.';
