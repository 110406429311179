import axios from 'axios';
import base64url from 'base64url';
import React from 'react';
import { withCookies } from 'react-cookie';
import { Redirect } from 'react-router';
import {
  Alert,
  Button,
  ColumnLayout,
  Icon,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import {
  API_ENDPOINT,
  ERROR_MESSAGE_SERVICE_API_ERROR,
  ERROR_MESSAGE_THROTTLED_REQUEST,
  HAS_MUTUAL_AUTH_COOKIE,
  IS_SAML_AUTH_COOKIE,
  IS_SAML_LOGOUT_COOKIE,
  SESSION_TOKEN_COOKIE,
} from '../../constants';
import AdAuthForm from '../AdAuthForm';

class EndpointHome extends React.Component {
  constructor(props) {
    super(props);
    this.endpointId = this.props.match.params.endpointId;
    this.onClickDownloadConfiguration = this.onClickDownloadConfiguration.bind(
      this,
    );
    this.onClickDownloadApp = this.onClickDownloadApp.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);

    const session = this.initializeSession();
    this.state = {
      adAuth: null,
      clientAppMetadata: null,
      configDownloadInProgress: false,
      errorMessage: null,
      loading: true,
      session: session,
    };
  }

  render() {
    if (this.state.loading === true) {
      return (
        <div className="custom-spinner">
          <Spinner size="large"></Spinner>
        </div>
      );
    }

    if (this.state.errorMessage !== null) {
      return <Alert type="error">{this.state.errorMessage}</Alert>;
    }

    if (this.state.session === null) {
      if (this.state.adAuth !== null) {
        return (
          <AdAuthForm endpointId={this.endpointId} adAuth={this.state.adAuth} />
        );
      } else {
        return null;
      }
    }

    if (this.state.session.cvpn !== this.endpointId) {
      this.removeSessionCookie();
      return <Redirect to="/" />;
    }

    const hasMutualAuth =
      this.props.cookies.get(HAS_MUTUAL_AUTH_COOKIE) === 'true';
    const mutualAuthAlertClassName = `awsui-util-mh-l ${
      hasMutualAuth ? '' : 'awsui-util-d-n'
    }`;
    return (
      <>
        <div className="logout-container awsui-util-container container-no-border max-width-container">
          <div className="awsui-util-f-r awsui-util-font-size-2">
            <Button icon="lock-private" onClick={this.onClickLogout}>
              Log out
            </Button>
          </div>
        </div>
        <div className="awsui-util-container awsui-util-no-gutters container-no-border max-width-container awsui-util-mt-xxl">
          <h2
            className="awsui-util-font-size-2 awsui-util-mt-xxl awsui-util-mb-s"
            style={{ color: '#FFFFFF' }}
          >
            Download the VPN client configuration file for the endpoint
          </h2>
          <div className="awsui-util-container client-config-container awsui-util-no-gutters">
            <div className="awsui-util-font-size-0 awsui-text-secondary awsui-util-mt-l awsui-util-mh-l">
              <i>Endpoint ID</i>
            </div>
            <div className="awsui-util-font-size-3 awsui-util-mh-l awsui-util-mb-l">
              {this.endpointId}
            </div>
            <div className={mutualAuthAlertClassName}>
              <Alert>
                This endpoint uses mutual authentication, hence you might need
                to update the configuration file with the client certificate and
                key after downloading it. Please reach out to your IT
                administrator for detailed instructions to update the VPN
                profile.
              </Alert>
            </div>
            <Button
              className="awsui-util-mv-l awsui-util-mh-l"
              onClick={this.onClickDownloadConfiguration}
              variant="primary"
            >
              {this.state.configDownloadInProgress ? (
                <Spinner></Spinner>
              ) : (
                <>
                  <Icon name="download"></Icon> Download client configuration
                </>
              )}
            </Button>
          </div>
        </div>
        <div className="awsui-util-container awsui-util-no-gutters container-no-border max-width-container awsui-util-mt-xxl">
          <h2 className="awsui-util-font-size-2 awsui-util-mt-xxl awsui-util-mb-s">
            Download the AWS Client VPN app for desktop
          </h2>
          <ColumnLayout className="awsui-util-m-s awsui-util-mt-l" columns={3}>
            <div data-awsui-column-layout-root="true">
              <div className="awsui-util-container app-container windows-app-container awsui-util-no-gutters awsui-util-mr-xxl">
                <div className="awsui-util-font-size-0 awsui-text-secondary awsui-util-mt-s awsui-util-mh-s">
                  <i>VPN Client</i>
                </div>
                <h3
                  id="windows-header"
                  className="awsui-util-font-size-3 awsui-util-mh-s awsui-util-mb-l"
                >
                  AWS Client VPN for Windows
                </h3>
                <div className="awsui-util-font-size-0 awsui-text-secondary awsui-util-mt-l awsui-util-mh-s">
                  <i>
                    Version:{' '}
                    {this.state.clientAppMetadata.Windows.ApplicationVersion}
                  </i>
                </div>
                <div className="awsui-util-font-size-0 awsui-text-secondary awsui-util-mh-s">
                  <i>
                    File size:{' '}
                    {this.formatBytes(
                      this.state.clientAppMetadata.Windows.FileSizeInBytes,
                    )}
                  </i>
                </div>
                <Button
                  id="download-button-windows"
                  aria-labelledby="download-button-windows-header"
                  className="awsui-util-mt-l awsui-util-mb-s awsui-util-mh-s"
                  onClick={() => this.onClickDownloadApp('Windows')}
                  variant="primary"
                >
                  <Icon name="download"></Icon> Download
                </Button>
              </div>
              <div className="awsui-util-container app-container osx-app-container awsui-util-no-gutters awsui-util-mr-xxl">
                <div className="awsui-util-font-size-0 awsui-text-secondary awsui-util-mt-s awsui-util-mh-s">
                  <i>VPN Client</i>
                </div>
                <h3
                  id="osx-header"
                  className="awsui-util-font-size-3 awsui-util-mh-s awsui-util-mb-l"
                >
                  AWS Client VPN for OSX
                </h3>
                <div className="awsui-util-font-size-0 awsui-text-secondary awsui-util-mt-l awsui-util-mh-s">
                  <i>
                    Version:{' '}
                    {this.state.clientAppMetadata.OSX.ApplicationVersion}
                  </i>
                </div>
                <div className="awsui-util-font-size-0 awsui-text-secondary awsui-util-mh-s">
                  <i>
                    File size:{' '}
                    {this.formatBytes(
                      this.state.clientAppMetadata.OSX.FileSizeInBytes,
                    )}
                  </i>
                </div>
                <Button
                  id="download-button-osx"
                  aria-labelledby="download-button-osx-header"
                  className="awsui-util-mv-l awsui-util-mh-s"
                  onClick={() => this.onClickDownloadApp('OSX')}
                  variant="primary"
                >
                  <Icon name="download"></Icon> Download
                </Button>
              </div>
              <div className="awsui-util-container app-container linux-app-container awsui-util-no-gutters awsui-util-mr-xxl">
                <div className="awsui-util-font-size-0 awsui-text-secondary awsui-util-mt-s awsui-util-mh-s">
                  <i>VPN Client</i>
                </div>
                <h3
                  id="linux-header"
                  className="awsui-util-font-size-3 awsui-util-mh-s awsui-util-mb-l"
                >
                  AWS Client VPN for Linux
                </h3>
                <div className="awsui-util-font-size-0 awsui-text-secondary awsui-util-mt-l awsui-util-mh-s">
                  <i>
                    Version:{' '}
                    {this.state.clientAppMetadata.Linux.ApplicationVersion}
                  </i>
                </div>
                <div className="awsui-util-font-size-0 awsui-text-secondary awsui-util-mh-s">
                  <i>
                    File size:{' '}
                    {this.formatBytes(
                      this.state.clientAppMetadata.Linux.FileSizeInBytes,
                    )}
                  </i>
                </div>
                <Button
                  id="download-button-linux"
                  aria-labelledby="download-button-linux-header"
                  className="awsui-util-mv-l awsui-util-mh-s"
                  onClick={() => this.onClickDownloadApp('Linux')}
                  variant="primary"
                >
                  <Icon name="download"></Icon> Download
                </Button>
              </div>
            </div>
          </ColumnLayout>
        </div>
        <div className="awsui-util-container awsui-util-no-gutters container-no-border max-width-container awsui-util-mt-xxl">
          For help getting started with AWS Client VPN, please visit the{' '}
          <a
            href="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/client-vpn-user-what-is.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            documentation
          </a>
          .
        </div>
        <div className="awsui-util-container awsui-util-no-gutters container-no-border max-width-container awsui-util-mt-xxl">
          By downloading the software client for AWS Client VPN, you agree to
          the AWS customer agreement, AWS service terms, and AWS privacy notice.
          If you already have an AWS customer agreement, you agree that the
          terms of that agreement govern your download and use of this product.
        </div>
      </>
    );
  }

  componentDidMount() {
    if (this.state.session === null) {
      this.getEndpointAuthenticationType();
    } else {
      this.getClientAppMetadata();
    }
  }

  initializeSession() {
    const sessionToken = this.props.cookies.get(SESSION_TOKEN_COOKIE);
    if (sessionToken === undefined) {
      return null;
    }

    if (process.env.REACT_APP_ENV === 'local') {
      return {
        cvpn: sessionToken,
      };
    }

    try {
      const jwtPayload = sessionToken.split('.')[1];
      return JSON.parse(base64url.decode(jwtPayload));
    } catch (e) {
      return null;
    }
  }

  removeSessionCookie() {
    this.props.cookies.remove(SESSION_TOKEN_COOKIE, {
      path: '/',
      secure: true,
    });
    this.props.cookies.remove(IS_SAML_AUTH_COOKIE, {
      path: '/',
      secure: true,
    });
  }

  getEndpointAuthenticationType() {
    this.setState({
      loading: true,
    });

    axios({
      method: 'get',
      url: `${API_ENDPOINT}/endpoints/${this.endpointId}/authentication-type`,
    })
      .then((response) => {
        this.handleAuthenticationTypeResponse(response);
      })
      .catch((error) => {
        console.log(error.response);
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data['Message']) ||
          ERROR_MESSAGE_SERVICE_API_ERROR;
        this.setState({
          errorMessage: errorMessage,
          loading: false,
        });
      });
  }

  handleAuthenticationTypeResponse(response) {
    const data = response.data;
    switch (data.AuthenticationType) {
      case 'SAML':
        this.handleSamlAuthenticationType(data);
        break;
      case 'AD':
        this.handleAdAuthenticationType(data, false);
        break;
      case 'AD_WITH_MFA':
        this.handleAdAuthenticationType(data, true);
        break;
      default:
        break;
    }
  }

  handleSamlAuthenticationType(data) {
    if (data.Location !== undefined) {
      window.location.href = data.Location;
    }
  }

  handleAdAuthenticationType(data, mfaEnabled) {
    this.setState({
      loading: false,
      adAuth: {
        mfaEnabled: mfaEnabled,
      },
    });
  }

  getClientAppMetadata() {
    this.setState({
      loading: true,
    });

    axios({
      method: 'get',
      url: `${API_ENDPOINT}/endpoints/${this.endpointId}/app`,
      withCredentials: true,
    })
      .then((response) => {
        this.setState({
          clientAppMetadata: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error.response);
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data['Message']) ||
          ERROR_MESSAGE_SERVICE_API_ERROR;
        const statusCode = (error.response && error.response.status) || 500;
        this.setState({
          errorMessage:
            statusCode === 403 ? ERROR_MESSAGE_THROTTLED_REQUEST : errorMessage,
          loading: false,
        });
      });
  }

  onClickDownloadConfiguration() {
    this.setState({
      configDownloadInProgress: true,
    });

    axios({
      method: 'get',
      url: `${API_ENDPOINT}/endpoints/${this.endpointId}/configuration`,
      withCredentials: true,
    })
      .then((response) => {
        this.setState({
          configDownloadInProgress: false,
        });
        this.downloadContentAsFile(
          response.data['ClientConfiguration'],
          `${this.endpointId}.ovpn`,
        );
      })
      .catch((error) => {
        console.log(error.response);
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data['Message']) ||
          ERROR_MESSAGE_SERVICE_API_ERROR;
        const statusCode = (error.response && error.response.status) || 500;
        this.setState({
          configDownloadInProgress: false,
          errorMessage:
            statusCode === 403 ? ERROR_MESSAGE_THROTTLED_REQUEST : errorMessage,
        });
      });
  }

  onClickDownloadApp(platform) {
    this.downloadUrlAsFile(
      this.state.clientAppMetadata[platform].DownloadURL,
      platform.toLowerCase(),
    );
  }

  onClickLogout() {
    const isSamlAuth = this.props.cookies.get(IS_SAML_AUTH_COOKIE) === 'true';
    this.props.cookies.set(IS_SAML_LOGOUT_COOKIE, isSamlAuth, {
      path: '/',
      secure: true,
      maxAge: 60,
    });
    this.removeSessionCookie();
    window.location.href = '/';
  }

  downloadContentAsFile(content, filename) {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(content),
    );
    element.setAttribute('download', filename);

    // Above code is equivalent to
    // <a href="path of file" download="file name">

    document.body.appendChild(element);
    // trigger onClick event
    element.click();
    document.body.removeChild(element);
  }

  downloadUrlAsFile(url, className) {
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('class', className);
    document.body.appendChild(element);
    if (window.Cypress) {
      // Do not attempt to actually download the file in test.
      // Just leave the anchor in there.
      return;
    }
    // trigger onClick event
    element.click();
    document.body.removeChild(element);
  }

  formatBytes(bytes, decimals = 2) {
    // source: https://stackoverflow.com/a/18650828
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}

export default withCookies(EndpointHome);
