import React from 'react';

import { AppLayout } from '@amzn/awsui-components-react/polaris';
import Content from './Content';

class App extends React.Component {
  render() {
    return (
      <>
        <AppLayout
          content={<Content />}
          contentType="no-paddings"
          navigationHide={true}
          toolsHide={true}
        />
      </>
    );
  }
}

export default App;
