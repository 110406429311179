import axios from 'axios';
import React from 'react';
import { withCookies } from 'react-cookie';
import {
  Alert,
  Button,
  ColumnLayout,
  FormField,
  FormSection,
  Input,
  Modal,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import {
  API_ENDPOINT,
  ERROR_MESSAGE_INVALID_CREDENTIALS,
  ERROR_MESSAGE_SERVICE_API_ERROR,
} from '../constants';

class AdAuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableLoginButton: false,
      errorMessage: null,
      isLoading: false,
      username: null,
      password: null,
      mfaStaticChallenge: null,
    };
    this.onClick = this.onClick.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.onUsernameInput = this.onUsernameInput.bind(this);
    this.onPasswordInput = this.onPasswordInput.bind(this);
    this.onMfaStaticChallengeInput = this.onMfaStaticChallengeInput.bind(this);
  }

  render() {
    return (
      <Modal
        visible={true}
        header={`AD authentication for ${this.props.endpointId}`}
        footer={
          <>
            <div
              className={
                this.state.errorMessage ? 'awsui-util-d-b' : 'awsui-util-d-n'
              }
            >
              <Alert className="awsui-util-mb-s" type="error">
                {this.state.errorMessage}
              </Alert>
            </div>
            <span className="awsui-util-f-r">
              <Button variant="link">Cancel</Button>
              <Button
                variant="primary"
                disabled={!this.state.enableLoginButton}
                onClick={this.onClick}
              >
                {this.state.isLoading ? <Spinner></Spinner> : 'Login'}
              </Button>
            </span>
          </>
        }
        onDismiss={this.onDismiss}
      >
        <FormSection header="Log in to your corporate AD">
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <div>
                <FormField label="Username"></FormField>
              </div>
              <div>
                <Input onInput={this.onUsernameInput} />
              </div>
              <div>
                <FormField label="Password"></FormField>
              </div>
              <div>
                <Input type="password" onInput={this.onPasswordInput} />
              </div>
              {this.props.adAuth.mfaEnabled ? (
                <>
                  <div>
                    <FormField label="MFA Static Challenge"></FormField>
                  </div>
                  <div>
                    <Input
                      type="password"
                      onInput={this.onMfaStaticChallengeInput}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </ColumnLayout>
        </FormSection>
      </Modal>
    );
  }

  onClick(e) {
    this.setState({
      enableLoginButton: false,
      errorMessage: null,
      isLoading: true,
    });

    axios({
      method: 'post',
      url: `${API_ENDPOINT}/endpoints/${this.props.endpointId}/authentication/ad`,
      data: {
        Username: this.state.username,
        Password: this.state.password,
        MFAStaticChallenge: this.state.mfaStaticChallenge,
      },
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        this.handleSuccessfulAdAuthResponse(response);
      })
      .catch((error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data['Message']) ||
          ERROR_MESSAGE_SERVICE_API_ERROR;
        const statusCode = (error.response && error.response.status) || 500;
        this.setState({
          enableLoginButton: true,
          errorMessage:
            statusCode === 401
              ? ERROR_MESSAGE_INVALID_CREDENTIALS
              : errorMessage,
          isLoading: false,
        });
      });
  }

  onUsernameInput(e) {
    const username = e.detail.value;
    const isMfaEnabled = this.props.adAuth.mfaEnabled;
    this.setState((state) => ({
      username: username,
      enableLoginButton:
        username &&
        state.password &&
        (!isMfaEnabled || state.mfaStaticChallenge),
    }));
  }

  onPasswordInput(e) {
    const password = e.detail.value;
    const isMfaEnabled = this.props.adAuth.mfaEnabled;
    this.setState((state) => ({
      password: password,
      enableLoginButton:
        state.username &&
        password &&
        (!isMfaEnabled || state.mfaStaticChallenge),
    }));
  }

  onMfaStaticChallengeInput(e) {
    const mfaStaticChallenge = e.detail.value;
    const isMfaEnabled = this.props.adAuth.mfaEnabled;
    this.setState((state) => ({
      mfaStaticChallenge: mfaStaticChallenge,
      enableLoginButton:
        state.username &&
        state.password &&
        (!isMfaEnabled || mfaStaticChallenge),
    }));
  }

  onDismiss(e) {
    window.location.href = '/';
  }

  handleSuccessfulAdAuthResponse(response) {
    window.location.reload(false);
  }
}

export default withCookies(AdAuthForm);
