import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from './routes/Home';
import EndpointHome from './routes/EndpointHome';

class Content extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="home-content awsui-util-container content-background">
          <Router>
            <Switch>
              <Route
                exact
                path="/endpoints/:endpointId"
                component={EndpointHome}
              />
              <Route path="/" component={Home} />
            </Switch>
          </Router>
        </div>
        <Footer />
      </>
    );
  }
}

export default Content;
