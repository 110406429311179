import React from 'react';
import awslogo from '../img/awslogo.png';

class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <div className="awsui-util-container max-width-container awsui-grid awsui-util-no-gutters">
          <div className="awsui-row">
            <div className="logo awsui-util-mt-s awsui-util-t-c col-xxs-2">
              <a href="/">
                <img src={awslogo} alt="AWS logo" />
              </a>
            </div>
            <h1 className="awsui-util-font-size-4 awsui-util-t-c col-xxs-8">
              AWS Client VPN Self-Service Portal
            </h1>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
