import React from 'react';
import { withCookies } from 'react-cookie';
import { Redirect } from 'react-router';
import { Alert } from '@amzn/awsui-components-react/polaris';
import EndpointIdForm from '../EndpointIdForm';
import { IS_SAML_LOGOUT_COOKIE } from '../../constants';

class Home extends React.Component {
  render() {
    if (this.props.location.pathname !== '/') {
      return <Redirect to="/" />;
    }

    const samlLogoutAlertVisible =
      this.props.cookies.get(IS_SAML_LOGOUT_COOKIE) === 'true';
    return (
      <>
        <div className="container-no-border awsui-util-container max-width-container">
          <div className={samlLogoutAlertVisible ? '' : 'awsui-util-d-n'}>
            <Alert>
              You have logged out of the endpoint session but be aware that your
              IDP SAML session may still be active until the session expiry time
              configured on your IDP by your IT administrator.
            </Alert>
          </div>
          <div className="awsui-util-font-size-3" style={{ color: '#FFFFFF' }}>
            Access your AWS Virtual Private Cloud via AWS Client VPN
            <p className="awsui-util-mt-s" style={{ color: '#FFFFFF' }}>
              You can use the self-service portal to get the tools that you’ll
              need to connect to AWS through a Client VPN endpoint. You can
              download the client configuration file (.ovpn file) for your VPN
              client application, and you can download the free AWS-provided VPN
              client. To get started, enter the ID of your Client VPN endpoint.
            </p>
          </div>
        </div>
        <EndpointIdForm />
      </>
    );
  }
}

export default withCookies(Home);
